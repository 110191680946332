<template>
  <div class="container light-style flex-grow-1 container-p-y">

    <h2 class="my-3">
      Settings
    </h2>

    <div class="card overflow-hidden">
      <div class="row no-gutters">
        <div class="col-md-3 pt-0">
          <div class="list-group list-group-flush account-settings-links">
            <router-link class="list-group-item list-group-item-action" to="/settings/user">Account</router-link>
            <router-link class="list-group-item list-group-item-action" to="/settings/faction">Faction</router-link>
          </div>
        </div>
        <!-- style="width:90%;margin:0 5%"-->
        
        <div class="col-md-9">
          <div class="card-body">
            <hr class="d-md-none border-top border-dark my-3"/>
            <router-view/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style type="text/css" scoped>
</style>
<script>
  export default {
  }
</script>
